<template>
  <div>
    <el-card>
      <div>
        <section id="banner">
          <div class="inner">
            <i class="far fa-frown action-icon-without-pointer" />
            <h1>404</h1>
            <h3 class="pb-2">This is not the page you're looking for.</h3>
            Go Back To
            <template v-if="lastPageIsIvyWay">
              <router-link :to="lastPageRoute">Last Page</router-link>
              Or
            </template>
            <router-link :to="{ name: 'LandingPage' }">Home</router-link>
          </div>
        </section>
      </div>
    </el-card>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */

export default {
  metaInfo() {
    return {
      title: "404 - Ivy-Way Academy"
    };
  },

  mixins: [],

  props: [],
  data() {
    return {};
  },
  computed: {
    lastPageIsIvyWay() {
      let lastPageRoute = localStorage.getItem("lastAccessRoutePath");
      return lastPageRoute ? true : false;
    },
    lastPageRoute() {
      return JSON.parse(localStorage.getItem("lastAccessRoutePath"));
    }
  },
  watch: {},

  mounted() {},

  methods: {}
};
</script>

<style scoped>
#banner {
  display: -ms-flexbox;
  -ms-flex-pack: center;
  -ms-flex-align: center;
  padding: 2em 0 6em 0;
  -moz-align-items: center;
  -webkit-align-items: center;
  -ms-align-items: center;
  align-items: center;
  display: -moz-flex;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -moz-justify-content: center;
  -webkit-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  min-height: 75vh;
  height: 75vh;
  position: relative;
  text-align: center;
  color: #303133;
}

.action-icon-without-pointer {
  font-size: 128px;
  margin: 12px 0px;
  animation: spin 3s;
  animation-iteration-count: infinite;
}

@keyframes spin {
  0% {
    transform: rotate(380deg);
  }

  70% {
    transform: rotate(330deg);
  }

  100% {
    transform: rotate(740deg);
  }
}
</style>
